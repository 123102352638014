<template>
  <div class="article-category">
    <div class="search-wrapper">
      <a-form-model
        :model="entity"
        :layout="'inline'"
        @submit.prevent="handleSearch"
      >
        <a-form-model-item prop="name" label="名称">
          <a-input
            v-model="entity.name"
            type="text"
            placeholder="输入搜索"
            allowClear
            @change="
              () => {
                if (entity.name == '') delete entity.name;
              }
            "
          ></a-input>
        </a-form-model-item>

        <a-form-model-item>
          <a-space>
            <a-button
              icon="search"
              :loading="loading"
              type="primary"
              htmlType="submit"
            >
              搜索
            </a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="table-wrapper">
      <y-table
        :columns="columns"
        :loading="loading"
        :service="articleCategoryService"
        :entity="entity"
        :filters="filters"
        @add="handleClickAdd"
      >
        <template slot="action" slot-scope="action">
          <a-button type="link" @click="handleEditClick(action.record)"
            >编辑</a-button
          >
          <a-button type="link" @click="handleDeleteClick(action.record)"
            >删除</a-button
          >
        </template>
      </y-table>
    </div>

    <a-drawer :visible="visible" width="50%" @close="handleClose">
      <a-form-model :model="articleCategory" @submit.prevent="handleSave">
        <a-form-model-item
          label="图片"
          prop="properties"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <y-upload
            v-model="articleCategory.properties"
            :maximumQuantity="1"
            :minimumQuantity="1"
            type="picture"
            accept="image/*"
          ></y-upload>
        </a-form-model-item>
        <a-form-model-item
          label="名称"
          prop="name"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-input v-model="articleCategory.name" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item
          label="语言"
          prop="language"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <y-radio
            v-model="articleCategory.language"
            :type="'Language'"
          ></y-radio>
        </a-form-model-item>
        <a-form-model-item
          label="排序"
          prop="sequence"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-input-number
            v-model="articleCategory.sequence"
            :defaultValue="1"
            :min="0"
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 8, offset: 4 }">
          <a-space>
            <a-button htmlType="submit" :loading="loading" type="primary">
              保存
            </a-button>
            <a-button :loading="loading" @click="handleClose"> 取消 </a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>
    <a-modal
      :confirm-loading="loading"
      title="排序"
      :visible="visibleSortModal"
      @cancel="handleSortModalCancel"
      @ok="handleSortModalOk"
    >
      <a-form-model :model="articleCategory">
        <a-form-model-item
          label="排序"
          prop="sequence"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-input-number
            v-model="articleCategory.sequence"
            :defaultValue="1"
            :min="0"
          ></a-input-number>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import ArticleCategoryService from "@/services/article-category";

const defaultArticleCategory = {};

export default {
  data() {
    return {
      articleCategoryService: ArticleCategoryService,
      entity: JSON.parse(JSON.stringify(defaultArticleCategory)),
      filters:{},
      visible: false,
      visibleSortModal: false,
      loading: false,
      columns: [
        {
          dataIndex: "id",
          title: "ID",
        },
        {
          dataIndex: "name",
          title: "名称",
          sorter: true,
        },
        {
          dataIndex: "language",
          title: "语言",
          customRender: function (text) {
            return <y-tag type={"Language"} value={text}></y-tag>;
          },
        },
        {
          dataIndex: "properties",
          title: "图片",
          scopedSlots: { customRender: "picture" },
        },
        // {
        //   dataIndex: "sequence",
        //   title: "权重",
        //   sorter: true,
        // },
        {
          dataIndex: "createTime",
          title: "创建时间",
          scopedSlots: { customRender: "dateTime" },
        },
        {
          dataIndex: "creater",
          title: "创建人",
        },
        {
          dataIndex: "updateTime",
          title: "更新时间",
          scopedSlots: { customRender: "dateTime" },
        },
        {
          dataIndex: "enabled",
          title: "状态",
          scopedSlots: { customRender: "enabled" },
        },
        {
          dataIndex: "updater",
          title: "更新人",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      // 虚拟模型用于添加和更新
      articleCategory: {},
    };
  },
  methods: {
    async handleSearch() {
      // 重置entity触发y-table重新获取数据
      this.entity = JSON.parse(JSON.stringify(this.entity));
    },
    handleClickAdd() {
      this.articleCategory = JSON.parse(JSON.stringify(defaultArticleCategory));
      this.visible = true;
    },
    handleClose() {
      this.articleCategory = JSON.parse(JSON.stringify(defaultArticleCategory));
      this.visible = false;
    },
    handleSortClick(record) {
      this.articleCategory = record;
      this.visibleSortModal = true;
    },
    handleEditClick(record) {
      this.articleCategory = record;
      this.visible = true;
    },
    async handleDeleteClick(record) {
      try {
        this.loading = true;
        await ArticleCategoryService.deleteEntity(record);
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.entity = JSON.parse(JSON.stringify(defaultArticleCategory));
      }
    },
    handleSortModalCancel() {
      this.articleCategory = JSON.parse(JSON.stringify(defaultArticleCategory));
      this.visibleSortModal = false;
    },
    async handleSortModalOk() {
      try {
        this.loading = true;

        await ArticleCategoryService.updateEntity({
          id: this.articleCategory.id,
          sequence: this.articleCategory.sequence,
        });
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.articleCategory = {};
        this.visibleSortModal = false;
        this.entity = JSON.parse(JSON.stringify(defaultArticleCategory));
      }
    },
    async handleSave() {
      try {
        this.loading = true;
        if (!this.articleCategory.id) {
          await ArticleCategoryService.addEntity(this.articleCategory);
        } else {
          await ArticleCategoryService.updateEntity(this.articleCategory);
        }
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.articleCategory = {};
        this.entity = JSON.parse(JSON.stringify(this.entity));
        this.visible = false;
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.article-category {
}
</style>